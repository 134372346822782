.newsletter-main {
  width: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  flex-direction: column;
  background: radial-gradient(
        217.93% 142.83% at 50% 0%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        129.94% 129.94% at 48.88% -59.34%,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    #989898;
  background-blend-mode: multiply, multiply, multiply, normal;
}
.newsletter-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fafafa;
}
.newsletter-input-box {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  max-width: 670px;
  min-width: 300px;
  width: 90%;
  height: 72px;
  padding: 10px ;
  background: linear-gradient(0deg, #e6f0ee 0%, #eef1f5 100%);
  border: 1px solid #a1a1aa;
  border-radius: 15px;
}
.newsletter-input-box > input {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0px 20px;
}
.newsletter-button {
  width: 189px;
  height: 56px;
  left: 1095px;
  top: 3672px;
  color: white;
  background: #4a76ee;
  border-radius: 10px;
}
