.why-head {
  font-weight: 400;
  font-size: 38px;
  line-height: 65px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  text-align: left;
}
.new-why-banner {
  width: 100%;
  background-image: url("../../assets/pattern\ 1\ \(1\).png");
  min-height: 80vh;
}
.why-imghead {
  width: 100%;
  /* background-image: url("../../assets/pattern\ 1\ \(1\).png"); */
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whylist-head {
  font-weight: 400;
  font-size: 28px;
  line-height: 65px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  text-align: left;
}
