.grace-main {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/pattern\ 1\ \(1\).png");
}
.grace-first-head {
  text-align: left;
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
  display: flex;
  align-items: center;
  color: #000000;
}
.grace-second-head {
  font-weight: 400;
  font-size: 64px;
  line-height: 87px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}
.grace-para {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}
.grace-button {
  width: 150px;
  height: 41px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  border-radius: 8px;
  color: white;
}
.about-us-head {
  font-weight: 400;
  font-size: 48px;
  line-height: 65px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.grace-about-para {
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: #000000;
}
.grace-features {
  background-image: url("../../assets/Vector.png");
  background-size: 60%;
  background-position: left;
  background-repeat: no-repeat;
  padding: 40px;
}
.features-head {
  font-weight: 400;
  font-size: 38px;
  line-height: 65px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  text-align: left;
}
.feature-card-container {
  display: flex;
  justify-content: center;
}
.feature-card {
  max-width: 80%;
  min-width: 60%;
  background: #ffffff;
  border-bottom: 2px solid #00d671;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-bottom: 30px;
}
.feature-anchor {
  text-align: right;
}
.grace-footer {
  background: linear-gradient(0deg, #e6f0ee 0%, #eef1f5 100%);
  border-radius: 0px 320px 0px 0px;
  overflow: hidden;
}
