.home-main{
    width: 100%;
    min-height: 90vh;
    background-image: url("../../assets/pattern\ 1\ \(1\).png");
}
.bgImage{
    background-image: url("../../assets/MicrosoftTeams-image\ \(1\).png");
    background-position:right;
    background-repeat: no-repeat;
    background-size: 70%;
    width: 100%;
    min-height: 90vh;
}
.btrlyf-text{
    font-size: 36px;
}
.card-img-top{
    height: 25vh;
}
.text_t{
    font-size: 45px;
    color: black;
    margin-top: 10px;
    margin-bottom: 20px;
}
.text{
    font-size: 16px;
    color: black;
    text-align: center;
}
.whybt-bg{
    width: 100%;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../assets/leaf1.png");
}
.our-services{
    background: linear-gradient(95.71deg, #34C9FF -4.22%, #5583FF 113.38%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    font-size: 36px;
    }
.card-service{
    width: 100%;
    padding: 10px;
    min-height: 85vh;
    margin-bottom: 40px;
}
.card-button{
    display: flex;
    justify-content: space-evenly;
    
}
.card-service>.card-body{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.WhyBtrlyf-heading{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 65px;
    color:#34C9FF ;
}
.img-btrlyf{
    height: auto;
    width: 300px;
}
.accordian-s{
    width: 100%;
}
.What-heading{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 65px;
    color:#34C9FF ;
    text-align: center;    
}
.nam{
    text-align: center;
    text-decoration: none;
}
.aboutus{
    list-style: none;
}
.underline{
    text-decoration:underline #43E97B ;
}
.says-bg{
    width: 100%;
    height: auto;
    background-image: url("../../assets/testimonybg.png");
    background-repeat: no-repeat;
    /* background: linear-gradient(0deg, #0BA360 0%, #3CBA92 100%); */
    background-size: cover;
    padding: 30px;
    background-position: center;
}
.referances{
    font-size: 20px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}
.news-bg{
    width: 100%;
    height: auto;
    background-image: url("../../assets/Union.png");
    background-repeat: no-repeat;
}
.news-blog{
    width: 100%;
}
.news-box{
display: flex;
position: relative;
top: -250px;
padding: 0px 15px;
/* background-color: rgba(0, 0, 0, 0.542); */
height: 250px;
z-index: 2;
align-items:flex-end;
background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
border-radius: 24px;
}
.news-text{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    
}
.box1{
    width: 75%;
}
.box2{
    width: 25%;
    text-align: center;
}
.news-date{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #FFFFFF;
}
.news-icon{
    color: #DE3C4B;
}