.new-navbar {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  background-image: url("../../assets/pattern\ 1\ \(1\).png");
  background-size: cover;
  background-repeat: no-repeat;
}
.new-nav-items {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.menu-item {
  background: #edf9fd;
  border-radius: 8px;
  color: #0088d6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: auto;
  margin: 0px 10px;
  padding: 5px 20px;
  font-size: 16px;
}
.btrlyf-apps {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4a76ee;
}
