.unit-heading{
    color:#34C9FF;
    text-align: center;
    margin: 15px;
}
.Faq-heading{
    color: #34C9FF;
}
.faq-item{
    background : #EDF9FD;
}
.underline{
    text-decoration: underline;
    text-decoration-color: #5dff34;
}
.faq-contact{
    border-radius: 0px 320px 0px 0px;
    overflow: hidden;
}
.unitBox-heading{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #101010;
}
.text{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #7C8DB0;
}
.result-text{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
}

.result-cop{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #000000;
}

.unitBox-result{
    display: flex;
    justify-content: space-evenly;
    background-color:#C7D5FA;
    padding: 10px;
}

.result-left{
    border-right: 1px solid #4A76EE;
    padding-right: 10px;
}

.unitBox{
    background: #EDF9FD;
    padding: 20px;
    margin: 60px 0px;
}

