.sticky_topbar_main {
  height: 70px;
  background-color: #232f3e;
}
.sticky-topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  /* background-color: #232f3e; */
  color: white;
  /* height: 70px; */
  /* position: fixed;
    z-index: 1000000; */
}
.sticky-topbar > h3 {
  margin-top: 15px;
}
.new_ui_switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}
.new_ui_switch1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-right: 10px;
  /* position: fixed; */
  right: 200px;
  top: 20px;
  /* z-index: -1; */
}
.new_ui_switch > p {
  margin-top: 5px;
}
.switch_button {
  margin-top: -10px;
}
.user_profile_avatar {
  border-radius: 50%;
  width: 50px;
}
.dropdown-menu {
  position: absolute;
  display: block;
  float: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  text-align: center;
  height: auto;
}
.nav-username-label {
  color: white;
}
.topbar_profile_dropdown_section {
  position: fixed;
  z-index: 1000000000;
}
