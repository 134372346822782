.contact-main {
  padding: 30px 0px;
  background-image: url("../../assets/Rectangle\ 318.png");
  min-height: 70vh;
}
.Contact-heading {
  font-weight: 400;
  font-size: 48px;
  line-height: 65px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline ;
}
.contact-form
{
    width: 90%;
    padding: 30px 70px;
    background: radial-gradient(217.93% 142.83% at 50% 0%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(129.94% 129.94% at 48.88% -59.34%, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #989898;
background-blend-mode: multiply, multiply, multiply, normal;
border-radius: 57px;
}
.contact-image
{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}