.about-us-head {
  font-weight: 400;
  font-size: 48px;
  line-height: 65px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.about-para {
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}
.about-image-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.stategic-partner {
  background: linear-gradient(0deg, #e6f0ee 0%, #eef1f5 100%);
  border-radius: 0px 172px 0px 0px;
  padding: 40px 0px;
}
.strategic-head {
  font-weight: 400;
  font-size: 38px;
  line-height: 65px;
  text-align: left;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}
.recorganised-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(128, 128, 128, 0.14),
    0px 1px 18px rgba(128, 128, 128, 0.12), 0px 3px 5px rgba(128, 128, 128, 0.2);
  border-radius: 12px;
  min-width: 80%;
}
