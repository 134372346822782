body {
  max-width: 100%;
  overflow-x: hidden;
}
p {
  margin-top: 5%;
}
h3 {
  text-align: center;
}
ul {
  margin-top: 5%;
}
.ddItem {
  padding: 0px !important;
}
.dropdown-menu {
  padding: 0%;
}
/* nav {
  display: flex;
} */
.box:hover {
  color: white;
  background-color: rgb(14, 14, 14);
  transition-duration: 0.4s;
}
/* .flex-container {
  margin: 10px;

  display: flex;
  justify-content: first baseline;
} */
.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}
/* .border {
  margin: 2px;
} */
/* .container{
  margin: 20px;
}
.row1{
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 450px; */
  width: 80%;
  padding: 5% 10%;
  /* height: 720px; */
  margin: auto;
  text-align: center;
}
.OurAppsCard
{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 450px; */
  width: 80%;
  padding: 5% 10%;
  /* height: 90%; */
  height: 570px;
  margin: auto;
  text-align: center;
}
/* .crd1{
  position: relative;
  flex:1;
  max-width: 450px;
  height: 150px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid rgb(0, 191, 255) !important;
  border-radius: 25px !important;
} */

.title {
  color: grey;
  font-size: 18px;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: none;
  color: rgb(0, 25, 252);
  background-color: #ffffff;
  /* text-align: center; */
  cursor: pointer;
  width: 100%;
  /* font-size: 18px; */
}

a {
  text-decoration: none !important;
  color: rgb(0, 0, 0);
}

button:hover {
  opacity: 0.7;
}
/* p{
  color: black;
} */

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}

.myButton:hover {
  border: 2px solid !important;
  background-color: rgb(24, 24, 24) !important;
  color: white !important;
}

.myCard {
  border: 2px solid rgb(0, 191, 255) !important;
  border-radius: 25px !important;
}
.ourApps-card-img
{
  width:60% !important;
  /* height: 300px !important; */
}
/* .myForm{
   width:100px !important;
  display: inline-block !important;
  backgroundColor:"grey"
 
} */
/* form{
  background-color: grey !important;
} */

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid grey;
  display: inline-block !important;
  background: rgb(207, 208, 238);
}
input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid grey;
  display: inline-block !important;
  background: rgb(209, 206, 248);
}

.textareaStyle {
  border: none !important;
  border-bottom: 2px solid grey !important;
  /* background-color: transparent; */
  resize: none;
  outline: none;
  background: rgb(105, 105, 105) !important;
}

.contactPara {
  display: inline;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.divPdf {
  display: flex;

  flex-direction: row;

  align-items: center;

  margin-left: 350px;

  margin-bottom: 30px;
}

.iconPdf {
  color: red;

  height: 50px;

  width: 50px;
}
.ui-toggle-switch
{
  display: flex;
  /* align-items: center; */
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 20px;
  font-size: 18px;
  background: #d5f1fa;
  border-radius: 8px;
  padding: 0px 10px;
  z-index: 99999;
}