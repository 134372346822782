.footer-main {
  background: linear-gradient(90deg, #0c1e30 4.88%, #000f1f 100%);
  padding: 20px 10px;
}

.footer-head {
  color: #0088d6;
  font-weight: 400;
  font-size: 24px;
}
.footer-contact
{
  display: flex;
}
.footer-text
{
  font-weight: 200;
  font-size: 16px;
  /* line-height: 22px; */
  color: #FAFAFA;
}