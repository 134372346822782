.pricing-head {
  font-weight: 400;
  font-size: 38px;
  line-height: 65px;
  background: linear-gradient(95.71deg, #34c9ff -4.22%, #5583ff 113.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  text-align: left;
}
.pricing-text {
  font-weight: 400;
  font-size: 48px;
  line-height: 45px;
  text-align: center;
  color: #0f172a;
}
.pricings {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.pricing-card {
  width: 70%;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}
.pricing-card1 {
  width: 70%;
  background: #e6f3fb;
  border-radius: 8px;
  padding: 20px;
}
.pricing-card2 {
  width: 70%;
  background: #3554a9;
  box-shadow: 0px 24px 38px 3px rgba(128, 128, 128, 0.14),
    0px 9px 46px 8px rgba(128, 128, 128, 0.12),
    0px 11px 15px rgba(128, 128, 128, 0.2);
  border-radius: 8px;
  padding: 20px;
}
.pricing-card-head {
  text-align: center;
}
.card-type {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #4a76ee;
}
.card-time {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: #606571;
}
.card-type1 {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: white;
}
.card-time1 {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: white;
}
.pricing-card-body {
  padding: 15px 0px;
}
.card-list-icon {
  color: #4a76ee;
  font-size: 16px;
  margin-right: 10px;
}
.card-list-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #0f172a;
}
.card-list-preicon {
  color: #fafafa;
  font-size: 16px;
  margin-right: 10px;
}
.card-list-premium {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fafafa;
}
.pricing-switch-container {
  display: flex;
  justify-content: center;
}
.pricing-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 300px;
  background: linear-gradient(0deg, #e6f0ee 0%, #eef1f5 100%);
  border-radius: 16px;
}
.monthly {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #86a3f4;
  margin-top: 5px;
}
.annualy {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fafafa;
  background: #4a76ee;
  border-radius: 8px;
  padding: 3px 15px;
  margin-top: 8px;
}
